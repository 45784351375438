import "core-js/modules/es.array.concat.js";
import UnauthorizedIcon from "~/components/UnauthorizedIcon.vue";
export default {
  __name: 'SimpleModelResultListItem',
  props: {
    dataObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    suffix: {
      type: String,
      "default": ""
    },
    symbol: {
      type: String,
      "default": "%"
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var togglePositiveNegativeClass = function togglePositiveNegativeClass(value) {
      return {
        positive: value >= 0,
        negative: value < 0
      };
    };
    var formattedValue = computed(function () {
      var _props$dataObj;
      return "".concat((_props$dataObj = props.dataObj) === null || _props$dataObj === void 0 ? void 0 : _props$dataObj.v).concat(props.symbol);
    });
    return {
      __sfc: true,
      props: props,
      togglePositiveNegativeClass: togglePositiveNegativeClass,
      formattedValue: formattedValue,
      UnauthorizedIcon: UnauthorizedIcon
    };
  }
};