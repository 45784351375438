import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.timers.js";
import { nextTick } from "vue";
import StyledButton from "~/components/StyledButton.vue";
import ModelStep from "~/components/valuationModels/ModelStep.vue";
import SimpleModelHeader from "~/components/valuationModels/SimpleModelHeader.vue";
import SimpleModelResults from "~/components/valuationModels/SimpleModelResults.vue";
import SimpleModelResultListItem from "~/components/valuationModels/SimpleModelResultListItem.vue";
import useBaseUtils from "~/functions/useBaseUtils";
import usePeValuationModel from "~/functions/usePeValuationModel";
import { templates } from "~/utils/valuationTransform/peValuationTemplates";
import { roundUpto } from "~/utils/constants/numbers";
import { isValidGuidedModel } from "~/utils/constants/valuationModel";
export default {
  __name: 'SimpleModel',
  props: {
    modelId: {
      type: String,
      required: false,
      "default": ""
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var $router = useRouter();
    var $route = useRoute();
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var _useBaseUtils = useBaseUtils(),
      customGroup = _useBaseUtils.customGroup,
      darkActive = _useBaseUtils.darkActive,
      dev = _useBaseUtils.dev,
      refCode = _useBaseUtils.refCode;
    var _usePeValuationModel = usePeValuationModel({
        templates: templates
      }),
      simpleModelProps = _usePeValuationModel.simpleModelProps,
      updateSingleRow = _usePeValuationModel.updateSingleRow;
    var stepsOpened = ref(props.modelId ? [true, true, true] : [true, false, false]);
    var loadingResults = ref(false);
    var showResults = ref(false);
    var tickersymbol = computed(function () {
      var _$store$state$ciq$tic;
      return (_$store$state$ciq$tic = $store.state.ciq.ticker) === null || _$store$state$ciq$tic === void 0 ? void 0 : _$store$state$ciq$tic.tickersymbol;
    });
    var loadingStore = computed(function () {
      return $store.state.ciq.fetchingEstimates || $store.state.ciq.fetchingValuation || $store.state.ciq.fetchingFinancials;
    });
    watch(loadingStore, function (loadingState) {
      var hasLoaded = loadingState === false;
      if (hasLoaded) {
        var validGuidedCompany = isValidGuidedModel($store, loadingState, dev.value, customGroup.value);
        if (!validGuidedCompany) {
          // this isn't a valid company to the
          // simple-model guided valuation
          // so we should redirect to the company page
          $router.push({
            path: "/models/company",
            query: {
              cid: $route.query.cid,
              tid: $route.query.tid,
              ref: refCode.value
            }
          });
        }
      }
    }, {
      immediate: true
    });
    var handleNextStep = function handleNextStep(nextStep) {
      stepsOpened.value = stepsOpened.value.map(function (_, i) {
        return i < nextStep;
      });
      nextTick(function () {
        scrollToDiv("model-step-".concat(nextStep));
      });
    };
    var scrollToDiv = function scrollToDiv(id) {
      var el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({
          behavior: "smooth"
        });
      }
    };
    var togglePositiveNegativeClass = function togglePositiveNegativeClass(value) {
      return {
        positive: value >= 0,
        negative: value < 0
      };
    };
    var generateResults = function generateResults() {
      loadingResults.value = true;
      setTimeout(function () {
        loadingResults.value = false;
        showResults.value = true;
      }, 1000);
    };
    var storeArray = computed(function () {
      return $store.state.valuationModel.data.simpleModelInputs;
    });
    var isEveryOptionFilled = computed(function () {
      return storeArray.value.every(function (el) {
        return el !== null;
      });
    });
    var isSaving = computed(function () {
      var _$store$state$valuati;
      return (_$store$state$valuati = $store.state.valuationModel) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati.loading) === null || _$store$state$valuati === void 0 ? void 0 : _$store$state$valuati.saving;
    });
    var revenueStepChart = computed(function () {
      var _simpleModelProps$val;
      return (_simpleModelProps$val = simpleModelProps.value) === null || _simpleModelProps$val === void 0 || (_simpleModelProps$val = _simpleModelProps$val.stepsChartDataArr) === null || _simpleModelProps$val === void 0 || (_simpleModelProps$val = _simpleModelProps$val.find(function (el) {
        return el.metric === "revenue";
      })) === null || _simpleModelProps$val === void 0 ? void 0 : _simpleModelProps$val.values;
    });
    var ebitStepChart = computed(function () {
      var _simpleModelProps$val2;
      return (_simpleModelProps$val2 = simpleModelProps.value) === null || _simpleModelProps$val2 === void 0 || (_simpleModelProps$val2 = _simpleModelProps$val2.stepsChartDataArr) === null || _simpleModelProps$val2 === void 0 || (_simpleModelProps$val2 = _simpleModelProps$val2.find(function (el) {
        return el.metric === "ebitMargin";
      })) === null || _simpleModelProps$val2 === void 0 ? void 0 : _simpleModelProps$val2.values;
    });
    var peStepChart = computed(function () {
      var _simpleModelProps$val3;
      return (_simpleModelProps$val3 = simpleModelProps.value) === null || _simpleModelProps$val3 === void 0 || (_simpleModelProps$val3 = _simpleModelProps$val3.stepsChartDataArr) === null || _simpleModelProps$val3 === void 0 || (_simpleModelProps$val3 = _simpleModelProps$val3.find(function (el) {
        return el.metric === "priceOverEarningsMultiple";
      })) === null || _simpleModelProps$val3 === void 0 ? void 0 : _simpleModelProps$val3.values;
    });
    var handleSave = function handleSave() {
      $store.dispatch("valuationModel/saveOrEditModel", {
        userTier: customGroup.value,
        refCode: refCode.value,
        simple: true
      });
    };
    return {
      __sfc: true,
      props: props,
      $router: $router,
      $route: $route,
      $store: $store,
      customGroup: customGroup,
      darkActive: darkActive,
      dev: dev,
      refCode: refCode,
      simpleModelProps: simpleModelProps,
      updateSingleRow: updateSingleRow,
      stepsOpened: stepsOpened,
      loadingResults: loadingResults,
      showResults: showResults,
      tickersymbol: tickersymbol,
      loadingStore: loadingStore,
      handleNextStep: handleNextStep,
      scrollToDiv: scrollToDiv,
      togglePositiveNegativeClass: togglePositiveNegativeClass,
      generateResults: generateResults,
      storeArray: storeArray,
      isEveryOptionFilled: isEveryOptionFilled,
      isSaving: isSaving,
      revenueStepChart: revenueStepChart,
      ebitStepChart: ebitStepChart,
      peStepChart: peStepChart,
      handleSave: handleSave,
      StyledButton: StyledButton,
      ModelStep: ModelStep,
      SimpleModelHeader: SimpleModelHeader,
      SimpleModelResults: SimpleModelResults,
      SimpleModelResultListItem: SimpleModelResultListItem,
      roundUpto: roundUpto
    };
  }
};