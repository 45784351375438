import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.is-finite.js";
import debounce from "lodash/debounce";
import useBaseUtils from "~/functions/useBaseUtils";
import ModelStepChart from "~/components/valuationModels/ModelStepChart.vue";
import StyledButton from "~/components/StyledButton.vue";
import StyledDialog from "~/components/StyledDialog.vue";
import { roundUpto } from "~/utils/constants/numbers";
export default {
  __name: 'ModelStep',
  props: {
    number: {
      type: Number,
      required: true
    },
    open: {
      type: Boolean,
      "default": false
    },
    title: {
      type: String,
      required: true
    },
    info: {
      type: String,
      "default": ""
    },
    last: {
      type: Boolean,
      "default": false
    },
    consensus: {
      type: Number,
      "default": 0
    },
    inputSuffix: {
      type: String,
      "default": "%"
    },
    stepChart: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    metricName: {
      type: String,
      "default": ""
    },
    tickerSymbol: {
      type: String,
      "default": ""
    },
    modelId: {
      type: String,
      "default": ""
    },
    updateRowAction: {
      type: Function,
      "default": function _default() {}
    },
    tooltipSuffix: {
      type: String,
      "default": ""
    },
    metricTitle: {
      type: String,
      "default": ""
    },
    chartDataSource: {
      type: String,
      "default": ""
    },
    chartMetrics: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    chartIso: {
      type: String,
      "default": "USD"
    }
  },
  emits: ["continue"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn,
      customGroup = _useBaseUtils.customGroup,
      refCode = _useBaseUtils.refCode;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var focused = ref(false);
    var submitAttempt = ref(0);
    var inputRef = ref(null);
    var chartDialog = ref(false);
    var displayButton = ref(!props.modelId);
    var storeIndex = computed(function () {
      return props.number - 1;
    });
    var storeValue = computed(function () {
      var _$store$state$valuati;
      var index = storeIndex.value;
      return (_$store$state$valuati = $store.state.valuationModel) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati.data) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati.simpleModelInputs) === null || _$store$state$valuati === void 0 ? void 0 : _$store$state$valuati[index];
    });
    var disabledContinueBtn = computed(function () {
      return !isValid.value;
    });
    var isValid = computed(function () {
      return Number.isFinite(storeValue.value) && storeValue.value;
    });
    var modelId = computed(function () {
      return "model-step-".concat(props.number);
    });
    var chartTitle = computed(function () {
      if (props.metricTitle) {
        return props.metricTitle;
      }
      return "".concat(props.tickerSymbol, " ").concat(props.metricName);
    });
    var handleContinueBtn = function handleContinueBtn() {
      if (isValid.value) {
        displayButton.value = false;
        emit("continue", props.number + 1);
        return;
      }
      submitAttempt.value++;
      inputRef.value.focus();
    };
    var applyConsensus = function applyConsensus() {
      var newValue = roundUpto(props.consensus, 2);
      changeStoreValue(newValue);
    };
    var handleChartDialog = function handleChartDialog() {
      chartDialog.value = true;
    };
    var changeStoreValue = function changeStoreValue(number) {
      var mappedIds = {
        1: "revenueChangeYoY",
        2: "ebitMargin",
        3: "priceOverEarningsMultiple"
      };
      var rowId = mappedIds[props.number];
      var finalValue = number === 0 ? 0.01 : number;
      var updatedObj = props.updateRowAction(rowId, finalValue, 1);
      $store.dispatch("valuationModel/updateSimpleModelInput", {
        index: storeIndex.value,
        value: number,
        stepNumber: props.number,
        userTier: customGroup.value,
        refCode: refCode.value,
        updatedObj: updatedObj
      });
    };
    var handleDebouncedInput = debounce(function (value) {
      var rounded = roundUpto(Number(value), 2);
      changeStoreValue(rounded);
    }, 400);
    return {
      __sfc: true,
      props: props,
      darkActive: darkActive,
      I18nFn: I18nFn,
      customGroup: customGroup,
      refCode: refCode,
      $store: $store,
      emit: emit,
      focused: focused,
      submitAttempt: submitAttempt,
      inputRef: inputRef,
      chartDialog: chartDialog,
      displayButton: displayButton,
      storeIndex: storeIndex,
      storeValue: storeValue,
      disabledContinueBtn: disabledContinueBtn,
      isValid: isValid,
      modelId: modelId,
      chartTitle: chartTitle,
      handleContinueBtn: handleContinueBtn,
      applyConsensus: applyConsensus,
      handleChartDialog: handleChartDialog,
      changeStoreValue: changeStoreValue,
      handleDebouncedInput: handleDebouncedInput,
      ModelStepChart: ModelStepChart,
      StyledButton: StyledButton,
      StyledDialog: StyledDialog
    };
  }
};