var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"model-step-wrapper",class:{
    closed: !_vm.open,
  },attrs:{"id":_setup.modelId}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"number mr-2"},[_c('span',[_vm._v(_vm._s(_vm.number))])]),_vm._v(" "),_c('div',[_c('h5',[_vm._v("\n        "+_vm._s(_vm.title)+"\n        "),(_vm.info)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"small":"","color":"grey"}},on),[_vm._v("\n              mdi-information-outline\n            ")])]}}],null,false,879519481)},[_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.info)+"\n          ")])]):_vm._e()],1)])]),_vm._v(" "),_c('div',[_c('div',{staticClass:"model-step-content-wrapper pt-4",class:{
        'model-step-last': _vm.last && !_vm.open,
      }},[_c('div',{staticClass:"model-step-content",class:{
          'model-step-opened': _vm.open,
        }},[_c('div',{staticClass:"model-step-contents"},[_c('div',{staticClass:"model-step-contents-inputs"},[_c('v-text-field',{ref:"inputRef",staticClass:"numeric-input text-right",staticStyle:{"text-align":"right"},attrs:{"type":"number","hide-details":"auto","error-messages":_setup.submitAttempt && !_setup.isValid ? 'Please enter a valid number' : '',"outlined":"","suffix":_vm.inputSuffix,"placeholder":"15"},on:{"focus":function($event){_setup.focused = true},"blur":function($event){_setup.focused = false},"input":_setup.handleDebouncedInput},model:{value:(_setup.storeValue),callback:function ($$v) {_setup.storeValue=$$v},expression:"storeValue"}}),_vm._v(" "),_c(_setup.StyledButton,{staticClass:"consensus-btn rounded-lg mt-4",attrs:{"depressed":"","outlined":""},on:{"click":_setup.applyConsensus}},[_vm._v("\n              "+_vm._s(_setup.I18nFn("I don't know (choose consensus)"))+"\n            ")])],1),_vm._v(" "),_c('div',{staticClass:"model-step-contents-text rounded-lg"},[_vm._t("default"),_vm._v(" "),_c('div',{staticClass:"d-block text-right"},[_c('v-btn',{attrs:{"icon":"","plain":""},on:{"click":_setup.handleChartDialog}},[_c('v-icon',{staticClass:"material-symbols-outlined"},[_vm._v("area_chart")])],1)],1),_vm._v(" "),_c(_setup.StyledDialog,{attrs:{"max-width":"900px","autofocus":false,"visible":_setup.chartDialog},on:{"update:visible":function($event){_setup.chartDialog = $event}}},[_c('h3',{staticClass:"model-dialog-metric-title text-center"},[_vm._v("\n                "+_vm._s(_setup.chartTitle)+"\n              ")]),_vm._v(" "),_c(_setup.ModelStepChart,{attrs:{"source":_vm.chartDataSource,"metrics":_vm.chartMetrics,"title":_setup.chartTitle,"isocode":_vm.chartIso}})],1)],2)])]),_vm._v(" "),(_setup.displayButton && _vm.open && !_vm.last)?_c(_setup.StyledButton,{staticClass:"step-continue-btn mt-4 mt-sm-0 rounded-lg",class:{
          'black--text': _setup.darkActive,
        },attrs:{"depressed":"","color":"primaryAction","disabled":_setup.disabledContinueBtn},on:{"click":_setup.handleContinueBtn}},[_vm._v("\n        "+_vm._s(_setup.I18nFn("Continue"))+"\n      ")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }