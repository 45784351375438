import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.is-integer.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import dayjs from "~/utils/tools/dayjs";
import ResultsChart from "~/components/valuationModels/Pe/SimpleModel/ResultsChart.vue";
import ResultsHeader from "~/components/valuationModels/Pe/SimpleModel/ResultsHeader.vue";
import ResultsMessage from "~/components/valuationModels/Pe/SimpleModel/ResultsMessage.vue";
import SimpleCompanyActions from "~/components/valuationModels/SimpleCompanyActions.vue";
import SimpleCompanyMeta from "~/components/valuationModels/SimpleCompanyMeta.vue";
import useBaseUtils from "~/functions/useBaseUtils";
import { createCurrencyFormatter } from "~/utils/valuationTransform/peModel";
export default {
  __name: 'SimpleModelResults',
  props: {
    realizedAtDateObj: {
      type: Object,
      "default": function _default() {}
    },
    chartLabels: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    chartDataPoints: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    currentStockPrice: {
      type: Object,
      "default": function _default() {}
    },
    epsCagr: {
      type: Object,
      "default": function _default() {}
    },
    priceOverEarningsMultiple: {
      type: Object,
      "default": function _default() {}
    },
    dividendYield: {
      type: Object,
      "default": function _default() {}
    },
    irr: {
      type: Object,
      "default": function _default() {}
    },
    totalReturn: {
      type: Object,
      "default": function _default() {}
    },
    totalStockPrice: {
      type: Object,
      "default": function _default() {}
    }
  },
  emits: ["goBack"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      refCode = _useBaseUtils.refCode,
      I18nFn = _useBaseUtils.I18nFn;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var $route = useRoute();
    var $router = useRouter();
    var iso = computed(function () {
      var _props$currentStockPr;
      return props === null || props === void 0 || (_props$currentStockPr = props.currentStockPrice) === null || _props$currentStockPr === void 0 ? void 0 : _props$currentStockPr.iso;
    });
    var storeModelId = computed(function () {
      return $store.state.valuationModel.currentModelId;
    });
    var goBack = function goBack() {
      emit("goBack");
    };
    var formatValidCurrency = function formatValidCurrency(val, iso) {
      var digits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
      var formatter = createCurrencyFormatter({
        minDigits: digits,
        maxDigits: digits,
        isocode: iso
      });
      return formatter.format(val);
    };
    var formatWithCurrency = function formatWithCurrency(stockPriceObj) {
      var val = stockPriceObj === null || stockPriceObj === void 0 ? void 0 : stockPriceObj.v;
      if (!val) {
        return "";
      }
      var iso = stockPriceObj === null || stockPriceObj === void 0 ? void 0 : stockPriceObj.iso;
      var digits = 2;
      if (!iso) {
        return val.toString(digits);
      }
      return formatValidCurrency(val, iso);
    };
    var differenceInYears = computed(function () {
      var _props$realizedAtDate;
      var today = dayjs();
      var realizedAt = dayjs(props === null || props === void 0 || (_props$realizedAtDate = props.realizedAtDateObj) === null || _props$realizedAtDate === void 0 ? void 0 : _props$realizedAtDate.epoch);
      var diff = realizedAt.diff(today, "year", true);
      var decimals = Number.isInteger(diff) ? 0 : 1;
      return diff.toFixed(decimals);
    });
    var maxValue = computed(function () {
      var _props$currentStockPr2, _props$currentStockPr3;
      var highestValue = Math.max.apply(Math, _toConsumableArray(props.chartDataPoints.map(function (arr) {
        return arr[1];
      })));
      if (highestValue > (props === null || props === void 0 || (_props$currentStockPr2 = props.currentStockPrice) === null || _props$currentStockPr2 === void 0 ? void 0 : _props$currentStockPr2.v)) {
        return highestValue;
      }
      return props === null || props === void 0 || (_props$currentStockPr3 = props.currentStockPrice) === null || _props$currentStockPr3 === void 0 ? void 0 : _props$currentStockPr3.v;
    });
    var getUpdatedLabelText = function getUpdatedLabelText(stockPriceObj, isExporting) {
      var translateX = isExporting ? 4 : 3;
      return "<div style=\"padding-left:16px; margin-top:3px; margin-right:10px;\">\n                <svg class=\"d-inline-block\" width=\"10\" height=\"16\" viewBox=\"0 0 10 16\" xmlns=\"http://www.w3.org/2000/svg\" style=\"transform: translate3d(".concat(translateX, "px, 4.5px, 0)\">\n                  <path d=\"M10 0 L0 8 L10 16 Z\" fill=\"#FF9F0F\"/>\n                </svg>\n                <div class=\"d-inline-block\" style=\"height:16px; line-height: 16px; background-color: #FF9F0F; color: #2F3033; font-size: 10px; padding-right:4px;\">\n                  ").concat(formatWithCurrency(stockPriceObj), "\n                  Buy Price\n                </div>\n              </div>");
    };
    var chartOptions = computed(function () {
      var _props$currentStockPr4, _props$currentStockPr5;
      return {
        chart: {
          type: "line",
          margin: [20, 20, 40, 70],
          // top, right, bottom, left
          spacing: [0, 0, 0, 0],
          // top, right, bottom, left
          backgroundColor: "transparent",
          height: 256,
          style: {
            fontFamily: "\"Roboto\", sans-serif"
          },
          zooming: {
            mouseWheel: false
          }
        },
        title: {
          text: ""
        },
        legend: {
          enabled: false
        },
        stockTools: {
          gui: {
            enabled: false
          }
        },
        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: props.chartLabels,
          labels: {
            style: {
              fontSize: "13px",
              color: darkActive.value ? "white" : "#6e6e6e",
              fontWeight: "bold"
            },
            rotation: 0
          }
        },
        yAxis: {
          title: {
            text: ""
          },
          max: Math.round(maxValue.value),
          gridLineWidth: 0,
          labels: {
            style: {
              color: darkActive.value ? "white" : "#6e6e6e",
              fontSize: "13px",
              fontWeight: "bold"
            },
            formatter: function formatter() {
              return formatValidCurrency(this.value, iso.value, 0);
            }
          },
          plotLines: [{
            value: props === null || props === void 0 || (_props$currentStockPr4 = props.currentStockPrice) === null || _props$currentStockPr4 === void 0 ? void 0 : _props$currentStockPr4.v,
            color: "#FF9F0F",
            width: 1,
            zIndex: 3,
            label: {
              backgroundColor: "var(--tikr-orange)",
              align: "right",
              verticalAlign: "bottom",
              style: {
                color: "#FF9F0F"
              },
              useHTML: true,
              text: getUpdatedLabelText(props === null || props === void 0 ? void 0 : props.currentStockPrice, false),
              textFn: getUpdatedLabelText,
              textFnParams: [props === null || props === void 0 ? void 0 : props.currentStockPrice]
            }
          }],
          plotBands: [{
            from: 0,
            to: props === null || props === void 0 || (_props$currentStockPr5 = props.currentStockPrice) === null || _props$currentStockPr5 === void 0 ? void 0 : _props$currentStockPr5.v,
            color: "rgba(253, 242, 200, ".concat(darkActive.value ? 0.2 : 0.4, ")"),
            zIndex: 2
          }]
        },
        series: [{
          data: props.chartDataPoints.map(function (arr) {
            return arr[1];
          }),
          color: "#444",
          lineColor: darkActive.value ? "#D8711F" : "var(--colors-grey-500)",
          zIndex: 5
        }],
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          }
        }
      };
    });
    var goToAdvancedMode = function goToAdvancedMode() {
      $router.push({
        path: "/stock/model",
        query: {
          cid: $route.query.cid,
          tid: $route.query.tid,
          ref: refCode.value,
          modelId: storeModelId.value
        }
      });
    };
    return {
      __sfc: true,
      props: props,
      darkActive: darkActive,
      refCode: refCode,
      I18nFn: I18nFn,
      $store: $store,
      emit: emit,
      $route: $route,
      $router: $router,
      iso: iso,
      storeModelId: storeModelId,
      goBack: goBack,
      formatValidCurrency: formatValidCurrency,
      formatWithCurrency: formatWithCurrency,
      differenceInYears: differenceInYears,
      maxValue: maxValue,
      getUpdatedLabelText: getUpdatedLabelText,
      chartOptions: chartOptions,
      goToAdvancedMode: goToAdvancedMode,
      ResultsChart: ResultsChart,
      ResultsHeader: ResultsHeader,
      ResultsMessage: ResultsMessage,
      SimpleCompanyActions: SimpleCompanyActions,
      SimpleCompanyMeta: SimpleCompanyMeta
    };
  }
};