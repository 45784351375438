var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"simple-model-results-wrapper rounded-lg py-4 py-md-8 px-4 px-sm-12",attrs:{"elevation":"0"}},[_c('div',{staticClass:"results-header-grid"},[_c(_setup.SimpleCompanyMeta),_vm._v(" "),_c('div',{staticClass:"align-self-center"},[_c('h4',[_vm._v("\n        "+_vm._s(`${_setup.I18nFn("Guided Valuation Model")} - ${_setup.I18nFn("Results")}`)+"\n      ")])]),_vm._v(" "),_c(_setup.SimpleCompanyActions,{staticClass:"actions-container",attrs:{"saving-state":false,"show-share-link":true,"data":{
        realizedAtDateObj: _vm.realizedAtDateObj,
        differenceInYears: _setup.differenceInYears,
        realizedAtDate: _vm.realizedAtDateObj?.fiscalDate,
        stockPrice: _vm.currentStockPrice,
        epsCagr: _vm.epsCagr,
        priceOverEarningsMultiple: _vm.priceOverEarningsMultiple,
        dividendYield: _vm.dividendYield,
        chartOptions: _setup.chartOptions,
        irr: _vm.irr,
        totalStockPrice: _vm.totalStockPrice,
        totalReturn: _vm.totalReturn,
      }}})],1),_vm._v(" "),_c(_setup.ResultsHeader,{attrs:{"realized-at-date-obj":_vm.realizedAtDateObj,"difference-in-years":_setup.differenceInYears,"realized-at-date":_vm.realizedAtDateObj?.fiscalDate,"stock-price":_vm.currentStockPrice,"irr":_vm.irr,"total-return":_vm.totalReturn,"total-stock-price":_vm.totalStockPrice}}),_vm._v(" "),_c(_setup.ResultsChart,{staticClass:"my-6 pa-2",attrs:{"chart-options":_setup.chartOptions}}),_vm._v(" "),_c(_setup.ResultsMessage,{staticClass:"mb-4",attrs:{"eps-cagr":_vm.epsCagr,"price-over-earnings-multiple":_vm.priceOverEarningsMultiple,"dividend-yield":_vm.dividendYield}}),_vm._v(" "),_c('StyledButton',{staticClass:"simple-results-advanced-btn rounded-lg",class:{
      'black--text': _setup.darkActive,
    },attrs:{"depressed":"","color":"primaryAction","disabled":!_setup.storeModelId},on:{"click":_setup.goToAdvancedMode}},[_vm._v("\n    "+_vm._s(_setup.I18nFn("See the underlying financial model used for this projection"))+"\n    "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1),_vm._v(" "),_c('div',{staticClass:"mt-4"},[_c('StyledButton',{staticClass:"back-link pa-0",attrs:{"plain":"","ripple":false},on:{"click":_setup.goBack}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v("\n      "+_vm._s(_setup.I18nFn("go back"))+"\n    ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }